/* Service Card */
.service-card {
    position: relative;
    width: 19.01vw;
    height: 23.54;
    background: #FFFFFF;
    box-shadow: 0px 20px 50px rgba(34, 47, 62, 0.1);
}

.service-card_icon {
    position: absolute;
    width: 4.16vw;
    height: 4.16vw;
    top: 2.08vw;
    left: 2.08vw;
}

.service-card_icon .eclipse {
    position: relative;
    width: 4.16vw;
    height: 4.16vw;
    background: #bedded;
    border-radius: 50%;
}

.eclipse .eclipse-icon {
    position: absolute;
    top: 1.04vw;
    left: 1.04vw;
    width: 2.08vw;
    height: 2.08vw;
}

.service-card_title {
    position: absolute;
    width: 14.06vw;
    height: 3.75vw;
    top: 7.29vw;
    left: 2.08vw;
}

.service-card_title h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25vw;
    line-height: 1.875vw;
    letter-spacing: -0.5px;
    color: #000000;
}

.service-card_seperator {
    position: absolute;
    top: 12.6vw;
    left: 2.08vw;
    width: 14.84vw;
    height: 0px;
    border: 1px solid #DEDEDE;
}

.service-card_description {
    position: absolute;
    top: 14.16vw;
    left: 2.08vw;
    width: 14.84vw;
    height: 7.03vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.83vw;
    line-height: 170%;
    color: #5F6161;
}

.service-card_badge_isNew {
    position: absolute;
    top: 0;
    left: 12.86vw;
    width: 6.66vw;
    height: 3.64vw;
}

.badge_isNew_retangle_1 {
    position: absolute;
    width: 6.66vw;
    height: 2.6vw;
    left: 0;
    top: 0.52vw;
    background: #00b5d9;
}

.retangle_1_arrow {
    width: 0;
    height: 0;
    border-top: 1.3vw solid transparent;
    border-bottom: 1.3vw solid transparent;
    border-left: 1.3vw solid white;
}

.badge_isNew_retangle_1 h2 {
    position: absolute;
    top: 0.46vw;
    left: 2.6vw;
    width: 2.5vw;
    height: 1.6vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.14vw;
    line-height: 1.6vw;
    color: #FFFFFF;
}

.badge_isNew_retangle_2 {
    position: absolute;
    width: 0;
    height: 0;
    left: 6.14vw;
    top: 0;
    background: #00b5d9;
    border-bottom: 0.52vw solid transparent;
    border-right: 0.52vw solid #E7F5EF;
    border-top: 0.05vw solid #E7F5EF;
}

.badge_isNew_retangle_3 {
    position: absolute;
    width: 0;
    height: 0;
    left: 6.14vw;
    top: 3.12vw;
    background: #00b5d9;

    border-top: 0.52vw solid transparent;
    border-right: 0.52vw solid #E7F5EF;
    border-bottom: 0.05vw solid #E7F5EF;
}

@media only screen and (max-width: 414px) {

    /* Service Card */
    .service-card {
        width: 315px;
        height: 315px;

        background: #FFFFFF;
        box-shadow: 0px 20px 50px rgba(34, 47, 62, 0.1);
    }

    .service-card_icon {
        width: 55px;
        height: 55px;
        left: 30px;
        top: 40px;
    }

    .service-card_icon .eclipse {
        position: relative;
        width: 55px;
        height: 55px;
        background: #bedded;
    }

    .eclipse .eclipse-icon {
        position: absolute;
        width: 27.5px;
        height: 27.5px;
        left: 13.75px;
        top: 13.75px;
    }

    .service-card_title {
        left: 100px;
        top: 41px;
        width: 185px;
        height: 54px;
    }

    .service-card_title h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: -0.5px;
        color: #000000;
    }

    .service-card_seperator {
        top: 125px;
        left: 30px;
        width: 255px;
        height: 0px;
        border: 1px solid #DEDEDE;
    }

    .service-card_description {
        top: 155px;
        left: 30px;
        width: 255px;
        height: 120px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #5F6161;
    }

    .service-card_badge_isNew {
        top: 0;
        left: 246px;
        width: 75px;
        height: 40px;
    }

    .badge_isNew_retangle_1 {
        width: 75px;
        height: 28.57px;
        left: 0;
        top: 5.71px;
        background: #00b5d9;
    }

    .retangle_1_arrow {
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid white;
    }

    .badge_isNew_retangle_1 h2 {
        position: absolute;
        top: 6.29px;
        left: 26.17px;
        width: 35px;
        height: 16px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #FFFFFF;
    }

    .badge_isNew_retangle_2 {
        width: 0;
        height: 0;
        left: 69px;
        top: 0;
        background: #00b5d9;

        border-bottom: 7px solid transparent;
        border-right: 7px solid #005088;
        border-top: 1px solid #005088;
    }

    .badge_isNew_retangle_3 {
        width: 0;
        height: 0;
        left: 69px;
        top: 34px;
        background: #00b5d9;

        border-top: 7px solid transparent;
        border-right: 7px solid #005088;
        ;
        border-bottom: 1px solid #005088;
        ;
    }
}

@media screen and (min-width: 377px) and (max-width: 481px) {

    /* Service Card */
    .service-card {
        width: 315px;
        height: 315px;

        background: #FFFFFF;
        box-shadow: 0px 20px 50px rgba(34, 47, 62, 0.1);
    }

    .service-card_icon {
        width: 55px;
        height: 55px;
        left: 30px;
        top: 40px;
    }

    .service-card_icon .eclipse {
        position: relative;
        width: 55px;
        height: 55px;
        background: #bedded;
    }

    .eclipse .eclipse-icon {
        position: absolute;
        width: 27.5px;
        height: 27.5px;
        left: 13.75px;
        top: 13.75px;
    }

    .service-card_title {
        left: 100px;
        top: 41px;
        width: 185px;
        height: 54px;
    }

    .service-card_title h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: -0.5px;
        color: #000000;
    }

    .service-card_seperator {
        top: 125px;
        left: 30px;
        width: 255px;
        height: 0px;
        border: 1px solid #DEDEDE;
    }

    .service-card_description {
        top: 155px;
        left: 30px;
        width: 255px;
        height: 120px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #5F6161;
    }

    .service-card_badge_isNew {
        top: 0;
        left: 246px;
        width: 75px;
        height: 40px;
    }

    .badge_isNew_retangle_1 {
        width: 75px;
        height: 28.57px;
        left: 0;
        top: 5.71px;
        background: #00b5d9;
    }

    .retangle_1_arrow {
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid white;
    }

    .badge_isNew_retangle_1 h2 {
        position: absolute;
        top: 6.29px;
        left: 26.17px;
        width: 35px;
        height: 16px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #FFFFFF;
    }

    .badge_isNew_retangle_2 {
        width: 0;
        height: 0;
        left: 69px;
        top: 0;
        background: #00b5d9;

        border-bottom: 7px solid transparent;
        border-right: 7px solid #005088;
        border-top: 1px solid #005088;
    }

    .badge_isNew_retangle_3 {
        width: 0;
        height: 0;
        left: 69px;
        top: 34px;
        background: #00b5d9;

        border-top: 7px solid transparent;
        border-right: 7px solid #005088;
        ;
        border-bottom: 1px solid #005088;
        ;
    }
}