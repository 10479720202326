.is-sticky {
    position: fixed !important;
    top: 0;
    width: 100%;
}

.button-header .Button-title {
    text-decoration: none !important;
}

#nav li a {
    color: rgb(30, 33, 50);
    text-decoration: none;
    line-height: 1.04vw;
    padding: 0.5625rem 0.9375rem;
    font-size: 0.83vw;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    box-sizing: border-box;
    display: block;
    font-family: 'Poppins', sans-serif;

}


#nav>li {
    display: inline-block;
}

#nav>li>a:hover {
    color: white;
}

.nav-li:hover {
    color: white;
    background-color: #005088;
    border-radius: 100px;
}

.img_logo {
    height: 4.2vw;
    width: 11vw;
}

.site-logo {
    /* position: absolute;
    left: 19.27vw;
    top: 1.25vw; */
    margin-left: 19.27vw;
}

.site-navigation {
    display: block;
    position: absolute;
    left: 41.66vw;
    width: 100%;
}

.navbar {
    position: fixed;
    z-index: 10;
    top: 0;
    background-color: white;
    width: 100%;
    height: 5.2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.submenu {
    display: none;

}

.menu {
    display: none;
}

/* responsive */

@media only screen and (max-width: 414px) {
    .title-header .title {
        text-decoration: none;
    }

    .button-header .Button-title {
        text-decoration: none !important;
    }

    #nav li a {
        color: rgb(30, 33, 50);
        text-decoration: none;
        line-height: 1.04vw;
        padding: 0.5625rem 0.9375rem;
        font-size: 0.83vw;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        box-sizing: border-box;
        display: block;

    }


    #nav>li {
        display: inline-block;
    }

    #nav>li>a:hover {
        color: white;
    }

    .nav-li:hover {
        color: white;
        background-color: #005088;
        border-radius: 100px;
    }

    .img_logo {
        width: 130px;
        height: 55px;
    }

    .site-logo {
        position: absolute;
        left: 30px;
        
        margin-left: 0px;
    }

    .site-navigation {
        display: none;
    }

    .navbar {
        position: relative;
        width: 100%;
        height: 75px;
        left: 0px;
        top: 0px;
        background: #FFFFFF;
    }

    .menu {
        display: block;
        position: absolute;
        left: 86.4vw;
        top: 6.13vw;

    }

    .submenu {
        display: none;

    }

    .submenu.is-open {
        display: block;
        position: fixed;
        width: 315px;
        height: 100%;
        right: 0;
        top: 0px;
        background: #FFFFFF;
    }

    .icon-close {

        position: absolute;
        left: 266.64px;
        top: 20.64px;
    }

    .title-header {
        display: flex;

        flex-direction: column;
        justify-content: space-between;

    }

    .title {
        width: 118px;
        height: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        margin-left: 30px;
        margin-top: 60px;
    }

    .line {
        position: absolute;
        width: 255px;
        left: 30px;
        border: 0.5px solid #DEDEDE;
        margin-top: 30px;
    }

    .button-header {
        position: absolute;
        top: 469px;
        left: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 25px;
        gap: 8px;
        width: 255px;
        height: 50px;
        background-color: #005088;
        border-radius: 100px;
    }

    .Button-title {
        width: 78px;
        height: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
    }


}

@media screen and (min-width: 377px) and (max-width: 481px) {
    .title-header .title {
        text-decoration: none;
    }

    .button-header .Button-title {
        text-decoration: none !important;
    }

    #nav li a {
        color: rgb(30, 33, 50);
        text-decoration: none;
        line-height: 1.04vw;
        padding: 0.5625rem 0.9375rem;
        font-size: 0.83vw;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        box-sizing: border-box;
        display: block;
    }


    #nav>li {
        display: inline-block;
    }

    #nav>li>a:hover {
        color: white;
    }

    .nav-li:hover {
        color: white;
        background-color: #005088;
        border-radius: 100px;
    }

    .img_logo {
        width: 130px;
        height: 55px;
    }

    .site-logo {
        position: absolute;
        left: 30px;
      
        margin-left: 0px;
    }

    .site-navigation {
        display: none;
    }

    .navbar {
        position: relative;
        width: 100%;
        height: 75px;
        left: 0px;
        top: 0px;
        background: #FFFFFF;
    }

    .menu {
        display: block;
        position: absolute;
        left: 86.4vw;
        top: 6.13vw;

    }

    .submenu {
        display: none;

    }

    .submenu.is-open {
        display: block;
        position: fixed;
        width: 315px;
        height: 100%;
        right: 0;
        top: 0px;
        background: #FFFFFF;
    }

    .icon-close {

        position: absolute;
        left: 266.64px;
        top: 20.64px;
    }

    .title-header {
        display: flex;

        flex-direction: column;
        justify-content: space-between;

    }

    .title {
        width: 118px;
        height: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        margin-left: 30px;
        margin-top: 60px;
    }

    .line {
        position: absolute;
        width: 255px;
        left: 30px;
        border: 0.5px solid #DEDEDE;
        margin-top: 30px;
    }

    .button-header {
        position: absolute;
        top: 469px;
        left: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 25px;
        gap: 8px;
        width: 255px;
        height: 50px;
        background-color: #005088;
        border-radius: 100px;
    }

    .Button-title {
        width: 78px;
        height: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
    }


}