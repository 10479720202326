
/* .ourteam-top{
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
margin-bottom: 80px;
margin-left: 370px;

} */


/* .ourteam-bottom{
    margin-top: 100px;
    padding-bottom: 100px;
} */
/* .card-ourteam{
width: 210px;
height: 240px;
background: white;
z-index: 1;
} */

/* .card-ourteam{
   
    border-radius: 10px;
    width: 210px;
    height: 240px;
    margin-left: 20px;
    box-shadow: 0px 20px 60px rgba(34, 47, 62, 0.1);
    
} */
.div_circle {    border: 0.01px solid #005088;
    
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background-color:  #bedded;   
     display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
}

.list-card-ourteam{
    display: flex;
    justify-content: center;
    margin-top: 100px;
   
}

.rectangle_02{
    
position: absolute;
width: 786px;
height: 403px;
left: 1117px;
top: 703px;

background: #00b5d9;
opacity: 0.1;
}

.rectangle_03{
    position: absolute;
    width: 261px;
    height: 261px;
    left: 229px;
    top: 1200px;
    
    background: #00b5d9;
    opacity: 0.3;
    border-radius: 10px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.rectangle_04{
    position: absolute;
width: 113.75px;
height: 113.75px;
left: 417px;
top: 1150px;

opacity: 0.5;
border: 2px solid #00b5d9;
border-radius: 10px;
transform: matrix(-1, 0, 0, 1, 0, 0);
}
